/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

/*
https://gitlab.weinform.net/root/Dev/-/issues/1542

The problem is that the search term highlight is not visible 
in the track of the browser scrollbar due to the drawer.
*/
.mat-drawer-container {
  display: inline !important;

  &.mat-drawer-container-has-open {
    display: block !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 1em 0;
}

.mat-form-field-label-wrapper {
  top: -1.04375em;
  padding-top: 1.04375em;
}

.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: -1.25em;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1.25em !important;
}

.mat-mdc-header-row {
  background-color: #eef1ff !important;
}

.mat-mdc-card {
  padding: 10px;
}

.mat-mdc-tab.mdc-tab--active {
  background-color: #673ab7;
  * {
    color: #ffffff;
  }
}